import React, { useState, useRef } from 'react'
import IframeResizer from 'iframe-resizer-react';
// @mui
import { Avatar, Tooltip } from '@mui/material';
import Dialog from "@mui/material/Dialog";
// components
import { IconButtonAnimate } from '../../components/animate';
import Iconify from '../../components/Iconify';
// ----------------------------------------------------------------------

export default function Wallet() {

  const iframeRef = useRef(null);

  //Dialog states
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Dialog open={open} onClose={handleClose}>

          <IframeResizer
              forwardRef={iframeRef}
              heightCalculationMethod="lowestElement"
              src={`${process.env.REACT_APP_EMBEDDED_URL}/wallet`}
              style={{ minWidth: '100%', border: 0}}
          />

    </Dialog>

    <Tooltip title="Wallet">
      <IconButtonAnimate sx={{p: 0,}} onClick={handleClickOpen}>
       <Avatar sx={{ border: '1px dotted', borderColor: 'text.secondary', background: 'transparent', borderRadius: '50%' }}><Iconify icon="streamline-emojis:dollar-banknote" /></Avatar>
      </IconButtonAnimate>
    </Tooltip>
    </>
  );
}
