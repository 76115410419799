import React, { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import ClaimOnlyLayout from '../layouts/ClaimOnlyLayout';
import UserLayout from '../layouts/UserLayout';
import GateOnlyLayout from '../layouts/GateOnlyLayout';
//wagmi
import { useAccount } from 'wagmi';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen isDashboard={true} />}>
    <Component {...props} />
  </Suspense>
);

// Custom higher-order component for checking access token and isConnected
const ProtectedRoute = ({ element, ...rest }) => {
  const accessTokenExists = localStorage.getItem('access_token') !== null;
  const isConnected = useAccount().isConnected;
  const walletTypeDetails = localStorage.getItem('access_token');
  const parsed = JSON.parse(walletTypeDetails);
  const walletType = parsed?.wallet_type

  if (!accessTokenExists || (!isConnected && walletType === 'external')) {
    return <Navigate to="/" />;
  }

  return React.cloneElement(element, rest);
};

export default function Router() {
  return useRoutes([

    {
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ]
    },

    {
      element: <UserLayout />,
      children: [
        { path: '/profile', element: <ProtectedRoute element= {<Profile />} />},
        { path: '/rewards', element: <ProtectedRoute element= {<Rewards />} />},
        { path: '/settings', element: <ProtectedRoute element= {<Settings />} />},
        { path: '/leaderboard', element: <Leaderboard />},
      ]
    },

    {
      element: <ClaimOnlyLayout />,
      children: [
        { path: '/claim/:contractAddress/:tokenID', element: <Claiming/>},
        { path: '/nft/:contractAddress/:tokenID', element: <NFT/>},
      ],
    },

    {
      element: <GateOnlyLayout />,
      children: [
        { path: '/gate/:gateID', element: <Gate/>},
      ],
    },


  

  ]);
}


// Pages
const Home = Loadable(lazy(() => import('../pages/Home')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
const Rewards = Loadable(lazy(() => import('../pages/Rewards')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const Claiming = Loadable(lazy(() => import('../pages/Claiming')));
const NFT = Loadable(lazy(() => import('../pages/NFT')));
const Leaderboard = Loadable(lazy(() => import('../pages/Leaderboard')));
const Gate = Loadable(lazy(() => import('../pages/Gate')));