import { Outlet } from 'react-router-dom';
import { useAccount } from 'wagmi'
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
import MintConnect from '../pages/authentication/MintConnect';
import AccountPopover from '../layouts/header/AccountPopover';
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  },
  display: 'flex',
  justifyContent: 'space-between', 
  alignItems: 'center'
}));

const MintConnectContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '& > :first-child': {
    marginRight: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

export default function ClaimOnlyLayout() {

  const { isConnected } = useAccount();

  return (
    <>
      <HeaderStyle>
        <Logo />
        <MintConnectContainer>
          {isConnected || (localStorage.getItem('access_token') !== null) ? <AccountPopover /> :  <MintConnect />}
        </MintConnectContainer>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
