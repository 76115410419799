import { Outlet } from 'react-router-dom';
import { useAccount } from 'wagmi'
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

import { HEADER, NAVBAR } from '../config';
// components
import Logo from '../components/Logo';
import MintConnect from '../pages/authentication/MintConnect';
import AccountPopover from '../layouts/header/AccountPopover';
import Rewards from '../layouts/header/Rewards';
import Wallet from './header/Wallet';
// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  },
  display: 'flex',
  justifyContent: 'space-between', 
  alignItems: 'center'
}));

const MintConnectContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  '& > :first-child': {
    marginRight: theme.spacing(1),
  },
}));



const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));



// ----------------------------------------------------------------------

export default function UserLayout() {

  const { isConnected } = useAccount();

  //localstorage get access token
  const local_access_token = localStorage.getItem('access_token');
  const parsed_data = JSON.parse(local_access_token);
  const wallet_type = parsed_data && parsed_data?.wallet_type;

  return (
    <Box
    sx={{
      display: { lg: 'flex' },
      minHeight: { lg: 1 },
    }}
  >
      <HeaderStyle>
        <Logo />
        <MintConnectContainer>
          {isConnected || (localStorage.getItem('access_token') !== null) ?
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {wallet_type && wallet_type === 'embedded' && <Wallet />}
            <Rewards />
            <AccountPopover />
          </Stack>

          :
          
          <MintConnect />
        }
        </MintConnectContainer>
      </HeaderStyle>

      <MainStyle>
        <Outlet />
      </MainStyle>

    </Box>
  );
}
