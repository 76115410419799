import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


//localstorage get access token
const local_access_token = localStorage.getItem('access_token');
const access_token = JSON.parse(local_access_token);
//----------------------------------------------------------------------


//-----------------------------------------------------------------------------------------------------

//Get current user's data

export const getUserData = createAsyncThunk("contract/getUserData", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})


//Get nfts collected by the user
export const getUserNFT = createAsyncThunk("contract/getUserNFT", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/collected`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})


//Get unique name by wallet
export const getUserName = createAsyncThunk("contract/getUserName", async ({ username }) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/exists/${username}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        'Authorization': `Bearer ${access_token.token}`,
      },
    }).then((res) => res.json());
  })



//Update user's data

export const updateUserData = createAsyncThunk("contract/updateUserData", async ({updatedUserData, id }) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
            "x-auth-id": id,
        },
        body: JSON.stringify({
            avatar: updatedUserData.avatar,
            username: updatedUserData.username,
            socials: updatedUserData.socials
        })
    }).then((res) => 
    res.json()
    );
})

//Update user Rewards 

export const updateUserRewardStreak = createAsyncThunk("contract/updateUserRewardStreak", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/claim-gems`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})


//Get leaderboard lists
export const getLeaderBoardData = createAsyncThunk("contract/getLeaderBoardData", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/users/leaderboard`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
})

//-----------------------------------------------------------------------------------------------------


//--------------------------------------Claimable/Mitable-----------------------------------------------

//Get all minted nft list by wallet
export const getQuickMint = createAsyncThunk("contract/getQuickMint", async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/quick`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
        },
    }).then((res) => 
    res.json()
    );
})


//Get claimable by Id 
export const getClaimableID = createAsyncThunk("contract/getClaimableID", async ({contractAddress, tokenID}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/${contractAddress}/${tokenID}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        },
    }).then((res) => 
    res.json()
    );
})



//-----------------------------------Update claimable Count---------------------------------------------------------------------

export const updateClaimableCount = createAsyncThunk("contract/updateClaimableCount", async ({claimData}) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/claimable/claim_stats`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            'Authorization': `Bearer ${access_token.token}`,
            "x-auth-claimableID": claimData.claimableID,
        },
    }).then((res) => 
    res.json()
    );
})


//------------------------------------------------Relayer-------------------------------------------------------------

    //Relay claimable claim transaction
    export const relayGaslessClaim = createAsyncThunk("contract/relayGaslessClaim", async ({relayData}) => {
        return fetch(`${process.env.REACT_APP_RELAYER_URL}/api/relay/claim/${relayData.creator}/${relayData.claimer}/${relayData.claimableID}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "x-auth-relayer": relayData.relayer,
                "x-auth-creatorSignature": relayData.creatorSignature,
                "x-auth-tokenID": relayData.tokenID,
                "x-auth-amount": relayData.amount,
                "x-auth-totalAmount": relayData.totalAmount,
            },
        }).then((res) => 
        res.json()
        );
    })


//----------------------------------------------Gated Content--------------------------------------------------------

    //Get gated by Id for NFTs
    export const getGatedID = createAsyncThunk("contract/getGatedID", async ({id}) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/gate/content/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => 
        res.json()
        );
    })

//---------------------------------General API calls --------------------------
    //Get all general reward (gems) list
    export const getAllGeneralReward = createAsyncThunk("contract/getAllGeneralReward", async () => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/general/rewards`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => 
        res.json()
        );
    });

    
    //Get all general chains list
    export const getAllGeneralChains = createAsyncThunk("contract/getAllGeneralChains", async () => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/general/chains`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => 
        res.json()
        );
    });

//-------------------------------------------------Market API--------------------------------------------------------

    //Get market listing by Id 
    export const getListingByID = createAsyncThunk("contract/getListingByID", async ({contractAddress, tokenID}) => {
        return fetch(`${process.env.REACT_APP_MARKET_API_URL}/api/listing/${contractAddress}/${tokenID}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            },
        }).then((res) => 
        res.json()
        );
    })


    //Post request for creating a first listing
    export const createListing = createAsyncThunk("contract/createListing", async ({listingData}) => {
        return fetch(`${process.env.REACT_APP_MARKET_API_URL}/api/listing/list`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${access_token.token}`,
            },
            body: JSON.stringify({
                tokenID: listingData.tokenID,
                contractAddress: listingData.contractAddress,
                active_listings: listingData.active_listings,
                last_sale_price: listingData.last_sale_price
            })
        }).then((res) => 
        res.json()
        );
    })
    

    //Add new listing 
    export const addNewListing = createAsyncThunk("contract/addNewListing", async ({listingData, id}) => {
        return fetch(`${process.env.REACT_APP_MARKET_API_URL}/api/listing/add/${id}/`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${access_token.token}`
            },
            body: JSON.stringify({
                price: listingData.price,
                chainID: listingData.chainID,
                signature: listingData.signature,
                address: listingData.address,
                quantity: listingData.quantity
            })
        }).then((res) => 
        res.json()
        );
    });

    //Remove certain listing 
    export const removeListing = createAsyncThunk("contract/removeListing", async ({id}) => {
        return fetch(`${process.env.REACT_APP_MARKET_API_URL}/api/listing/remove/${id}/`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${access_token.token}`
            },
        }).then((res) => 
        res.json()
        );
    });

    //Vrify and update certain active listing 
    export const updateActiveListing = createAsyncThunk("contract/updateActiveListing", async ({listingData, id}) => {
        return fetch(`${process.env.REACT_APP_MARKET_API_URL}/api/listing/verify/item/${id}/`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Authorization': `Bearer ${access_token.token}`
            },
            body: JSON.stringify({
                amount: listingData.amount,
                sellerAddress: listingData.sellerAddress,
            })
        }).then((res) => 
        res.json()
        );
    });

//-------------------------------------------------------------------------------------------------------------------

//-------------------------------------------------------------------------------------------------------------------


const contractSlice = createSlice({
    name: "contract",
    initialState: {
        //User states
        error: null,
        userData: [], //user details 
        userNFTData: [],
        loading: false,
        loadingUser: false,
        loadingUserNFT: false,
        loadingUserDetails: false,
        loadingUserRewards: false,
        userName: null,

        //Leaderboard states
        leaderBoardData: [],
        loadingLeaderBoard: false,
        
        //NFT list states
        loadingMintList: false,
        quickMintList: [],
        
        //Claimable states
        claimableDetails: null, //claimable details
        updateClaimableDetails: null,
        loadingClaimableDetails: false,
        loadingClaimableDetailsUpdate: false,

        //Gate states
        loadingGated: false,
        gatedData: null,

        //Market states
        loadingListingDetails: false,
        listingDetails: null, //listing details
        loadingUpdateListing: false,

        //---------------------General APIs states-----------------------
        loadingGeneral: false,
        generalReward: [],
        generalChains: [],
        //--------------------------------------------
    
    },


    extraReducers: {


        //get users data
        [getUserData.pending]: (state, action) => {
            state.loadingUser = true;
        },
        [getUserData.fulfilled]: (state, action) => {
            state.loadingUser = false;
            state.userData = [action.payload];
        },
        [getUserData.rejected]: (state, action) => {
            state.loadingUser = false;
            state.error = action.payload;
        },

        //get users data
        [getUserNFT.pending]: (state, action) => {
            state.loadingUserNFT = true;
        },
        [getUserNFT.fulfilled]: (state, action) => {
            state.loadingUserNFT = false;
            state.userNFTData = [action.payload];
        },
        [getUserNFT.rejected]: (state, action) => {
            state.loadingUserNFT = false;
            state.error = action.payload;
        },

        //get user name by wallet
        [getUserName.pending]: (state, action) => {
            state.loading = true;
        },
        [getUserName.fulfilled]: (state, action) => {
            state.loading = false;
            state.userName = action.payload;
        },
        [getUserName.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },


        //Update users data
        [updateUserData.pending]: (state, action) => {
            state.loadingUserDetails = true;
        },
        [updateUserData.fulfilled]: (state, action) => {
            state.loadingUserDetails = false;
            state.userData = [action.payload];
        },
        [updateUserData.rejected]: (state, action) => {
            state.loadingUserDetails = false;
            state.error = action.payload;
        },

        //Update users reward streak
        [updateUserRewardStreak.pending]: (state, action) => {
            state.loadingUserRewards = true;
        },
        [updateUserRewardStreak.fulfilled]: (state, action) => {
            state.loadingUserRewards = false;
            state.userData = [action.payload];
        },
        [updateUserRewardStreak.rejected]: (state, action) => {
            state.loadingUserRewards = false;
            state.error = action.payload;
        },

        //get leaderboard list
        [getLeaderBoardData.pending]: (state, action) => {
            state.loadingLeaderBoard = true;
        },
        [getLeaderBoardData.fulfilled]: (state, action) => {
            state.loadingLeaderBoard = false;
            state.leaderBoardData = [action.payload];
        },
        [getLeaderBoardData.rejected]: (state, action) => {
            state.loadingLeaderBoard = false;
            state.error = action.payload;
        },


        //get all minted nft list by wallet
        [getQuickMint.pending]: (state, action) => {
            state.loadingMintList = true;
        },
        [getQuickMint.fulfilled]: (state, action) => {
            state.loadingMintList = false;
            state.quickMintList = [action.payload];
        },
        [getQuickMint.rejected]: (state, action) => {
            state.loadingMintList = false;
            state.error = action.payload;
        },


        //get claimable mint by Id
        [getClaimableID.pending]: (state, action) => {
            state.loadingClaimableDetails = true;
        },
        [getClaimableID.fulfilled]: (state, action) => {
            state.loadingClaimableDetails = false;
            state.claimableDetails = [action.payload];
        },
        [getClaimableID.rejected]: (state, action) => {
            state.loadingClaimableDetails = false;
            state.error = action.payload;
        },


        //update claimable count
        [updateClaimableCount.pending]: (state, action) => {
            state.loadingClaimableDetailsUpdate = true;
        },
        [updateClaimableCount.fulfilled]: (state, action) => {
            state.loadingClaimableDetailsUpdate = false;
            state.updateClaimableDetails = [action.payload];
        },
        [updateClaimableCount.rejected]: (state, action) => {
            state.loadingClaimableDetailsUpdate = false;
            state.error = action.payload;
        },
        

        //claimable claim relayer response
        [relayGaslessClaim.pending]: (state, action) => {
            state.loadingRelayer = true;
        },
        [relayGaslessClaim.fulfilled]: (state, action) => {
            state.loadingRelayer = false;
            state.relayerData = [action.payload];
        },
        [relayGaslessClaim.rejected]: (state, action) => {
            state.loadingRelayer = false;
            state.error = action.payload;
        },


        //get gated content by Id 
        [getGatedID.pending]: (state, action) => {
            state.loadingGated = true;
        },
        [getGatedID.fulfilled]: (state, action) => {
            state.loadingGated = false;
            state.gatedData = [action.payload];
        },
        [getGatedID.rejected]: (state, action) => {
            state.loadingGated = false;
            state.error = action.payload;
        },

        //get all general reward list 
        [getAllGeneralReward.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralReward.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalReward = [action.payload];
        },
        [getAllGeneralReward.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

         //get all general chains list 
        [getAllGeneralChains.pending]: (state, action) => {
            state.loadingGeneral = true;
        },
        [getAllGeneralChains.fulfilled]: (state, action) => {
            state.loadingGeneral = false;
            state.generalChains = [action.payload];
        },
        [getAllGeneralChains.rejected]: (state, action) => {
            state.loadingGeneral = false;
            state.error = action.payload;
        },

        //get market listing by Id
        [getListingByID.pending]: (state, action) => {
            state.loadingListingDetails = true;
        },
        [getListingByID.fulfilled]: (state, action) => {
            state.loadingListingDetails = false;
            state.listingDetails = [action.payload];
        },
        [getListingByID.rejected]: (state, action) => {
            state.loadingListingDetails = false;
            state.error = action.payload;
        },

        //create new listing
        [createListing.pending]: (state, action) => {
            state.loadingListingDetails = true;
        },
        [createListing.fulfilled]: (state, action) => {
            state.loadingListingDetails = false;
            state.listingDetails = action.payload;
        },
        [createListing.rejected]: (state, action) => {
            state.loadingListingDetails = false;
            state.error = action.payload;
        },

        //Add new listing
        [addNewListing.pending]: (state, action) => {
            state.loadingUpdateListing = true;
        },
        [addNewListing.fulfilled]: (state, action) => {
            state.loadingUpdateListing = false;
            state.listingDetails = [action.payload];
        },
        [addNewListing.rejected]: (state, action) => {
            state.loadingUpdateListing = false;
            state.error = action.payload;
        },

        //Remove listing
        [removeListing.pending]: (state, action) => {
            state.loadingUpdateListing = true;
        },
        [removeListing.fulfilled]: (state, action) => {
            state.loadingUpdateListing = false;
            state.listingDetails = [action.payload];
        },
        [removeListing.rejected]: (state, action) => {
            state.loadingUpdateListing = false;
            state.error = action.payload;
        },

        //Verify and update active listing
        [updateActiveListing.pending]: (state, action) => {
            state.loadingUpdateListing = true;
        },
        [updateActiveListing.fulfilled]: (state, action) => {
            state.loadingUpdateListing = false;
            state.listingDetails = [action.payload];
        },
        [updateActiveListing.rejected]: (state, action) => {
            state.loadingUpdateListing = false;
            state.error = action.payload;
        },

    }
})


export default contractSlice.reducer;